import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Wallet } from "../components/wallet"

const Header = ({ siteTitle }) => (
  <header>
    <nav><table><tbody><tr><td class="tdhomeicon">
      <ul class="homeicon"><Link to="/">
        <div>
          <StaticImage
            src="../images/BLUfinLogoOnly.svg"
            width={32}
            quality={95}
            formats={["auto", "svg", "webp", "avif"]}
            alt="BLUME finance -- AMM optimzed"
            style={{ marginBottom: `0` }}
            />
        </div></Link>
      </ul></td>
      <td class="tdmainnav"><ul class="mainnav">
        <li><Link to="/swap">Swap</Link></li>
        <li><Link to="/pools">Pools</Link></li>
        <li><Link to="/farms">Farms</Link></li>
        <li><Link to="/about">About</Link></li>
      </ul></td>
      <td class="tdwallet"><ul class="wallet">
        <li><Wallet /></li>
        {/* <li><Link to="/">&gt; Connect Wallet</Link></li> */}
      </ul></td></tr></tbody></table>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
